body {
  margin: 0;
  font-family: "EB Garamond", serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: rgb(68, 68, 68)
}

button{
  font-family: 'Source Sans Pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif
}

@media print{
  @page{
    margin: 0;
  }
  body{
    margin: 1.6cm
  }
  button{
    display:none;
  }
  .icon{
    display: none;
  }
}


