.title{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    position: fixed;
    z-index: 1000;
    height: 100vh;
    width: 100vw;
    top: 0;
    background-color: rgba(0, 0, 0, 0.774);
    color: antiquewhite;
    cursor: pointer;
    transition: all 2s;
    opacity:0;  /* make things invisible upon start */
    -webkit-animation:fadeIn 1.5s ease-in 0s 1 normal forwards;  /* call our keyframe named fadeIn, use animattion ease-in and repeat it only 1 time */
    -moz-animation:fadeIn 1.5s ease-in 0s 1 normal forwards;
    animation:fadeIn 1.5s ease-in 0s 1 normal forwards;
    padding: 0;
    box-sizing: border-box;
}

/* @-webkit-keyframes fadeIn {
    from {
        -webkit-opacity: 0;
        opacity: 0;
    }
    to {
        -webkit-opacity: 1;
        opacity: 1;
    }
}

@keyframes fadeIn {
    from {
        -webkit-opacity: 0;
        -moz-opacity: 0;
        -ms-opacity: 0;
        -o-opacity: 0;
        opacity: 0;
    }
    to {
        -webkit-opacity: 1;
        -moz-opacity: 1;
        -ms-opacity: 1;
        -o-opacity: 1;
        opacity: 1;
    }
} */

/* make keyframes that tell the start state and the end state of our object */
@-webkit-keyframes fadeIn { from { opacity:0; } to { opacity:1; } }
@-moz-keyframes fadeIn { from { opacity:0;  } to { opacity:1; } }
@keyframes fadeIn { from { opacity:0; } to { opacity:1; } }


.title h1{
    font-size: 20vw;
}

.instructions{
    margin: 0 50px;
}

@media screen and (max-width: 500px){
    .title{
        padding: 0;
    }
    .title h1{
        font-size: 30vw;
        margin: 0;
        padding: 0;
    }

    .instructions{
        margin-bottom: 200px;
    }

}
