.input-text{
    background-color: transparent;
    z-index: 100;
    border: 2px solid salmon;
    box-sizing: border-box;
    font-size: 16px;
    height: 24px;
    resize: none;
    flex-basis: 100%;
}

.input-button{
    border: none;
    height: 30px;
    font-size: 14px;
    flex-shrink: 0;
    transition: all .5s;
}

.add-button{
    background-color: rgb(55, 248, 238);
    flex-basis: 0;
    flex-grow: 2;
    flex-shrink: 1;
}

.add-button:hover{
    background-color: rgb(142, 252, 246);
}

.exit-button{
    background-color: rgb(71, 247, 36);
    flex-basis: 1;
    flex-grow: 1;
    flex-shrink: 2;
}

button{
    margin: 0;
}

.exit-button:hover{
    background-color: rgb(144, 250, 123);
}

.remove-button{
    background-color: rgb(255, 91, 91);
    flex-basis: 1;
    flex-grow: 1;
    flex-shrink: 2;
}

.remove-button:hover{
    background-color: rgb(255, 157, 157);
}

.input{
    width: 20rem;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    z-index: 5;
    box-sizing: border-box;
}

@media screen and (max-width: 500px){
    .input-text{
        border: solid 2px salmon;
        border-radius: 0;
        margin: 0;
        padding-left: 0;
    }
    .input{
        width: 250px;
    }

    .input-button{
        font-size: 12px;
        box-sizing: border-box;
    }

    .add-button{
        flex-basis: 100%;
        flex-grow: 2;
        flex-shrink: 1;
    }

    .exit-button{
        flex-basis: 0;
        flex-grow: 2;
        flex-shrink: 2;
    }

    .remove-button{
        flex-basis: 1;
        flex-grow: 1;
        flex-shrink: 2;
    }
}