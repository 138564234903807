.App {
  text-align: center;
}

.capture-img{
  z-index: 10;
  margin: 30px auto 0 auto;
}

@media screen and (max-width: 500px){
  .capture-img{
    margin: 0 auto;
    padding: 0;
  }
}

