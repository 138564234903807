.page{
    height: 700px;
    width: 500px;
    margin: 0 auto;
    margin-bottom: 20px;
    position: relative;
    -webkit-box-shadow: 10px 10px 28px -6px rgba(0,0,0,0.72);
    -moz-box-shadow: 10px 10px 28px -6px rgba(0,0,0,0.72);
    box-shadow: 10px 10px 28px -6px rgba(0,0,0,0.72);
    background-color: white;
}

@media print{
    .page{
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
    }
}

