.guide-vertical{
    border-left: 2px solid pink;
    position: absolute;
    display: block;
}   

.guide-horizontal{
    border-top: 2px solid pink;
    position: absolute;
    display:block;
}

@media screen and (max-width: 500px){
    .guide-vertical{
        border-left: 5px solid pink;
    }

    .guide-horizontal{
        border-top: 5px solid pink;
    }
}